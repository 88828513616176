import React, { useState } from 'react';
import './Main.css';
import { FaWhatsapp, FaTelegram, FaEnvelope } from 'react-icons/fa';
import IconTextGrid from './IconTextGrid';

export default function Main() {
  const phoneNumber = '87783361779';
  const [copied, setCopied] = useState(false);

  const copyPhoneNumber = () => {
    navigator.clipboard.writeText(phoneNumber)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Убираем сообщение через 2 секунды
      })
      .catch(err => console.error('Failed to copy phone number: ', err));
  };

  const iconSize = 40;

  return (
    <div className='main'>
      <div className="container">
        <header>
          <img className='logo' src='zhako-logo.svg' width="40px" height="40px" alt='logo zhako' />
          <span className="logo-text" translate="no">ZHAKO</span>

          <div className='contact'>
            <a className='padding-right-5px' href="https://api.whatsapp.com/send?phone=87783361779">
              <FaWhatsapp size={iconSize} color="black" />
            </a>
            <a className='padding-right-5px' href="https://t.me/Already_Exist_password">
              <FaTelegram size={iconSize} color="black" />
            </a>
            <a className='padding-right-last' href="mailto:xtentaclexyz@gmail.com">
              <FaEnvelope size={iconSize} color="black" />
            </a>
            <div className='number' onClick={copyPhoneNumber} style={{ cursor: 'pointer', position: 'relative' }}>
              {phoneNumber}
              {copied && <span className="copied-message">Скопировано!</span>}
            </div>
          </div>
        </header>

        <section className='first-section'>
          <h1>Веб-разработка и IT-услуги в Казахстане</h1>
          <p>Мы предлагаем профессиональные услуги веб-разработки в Казахстане. Создаем сайты, CRM, системы автоматизации и проводим SEO-продвижение.</p>

          <p className="typing-text">Мы используем современные технологии и нестандартный подход, чтобы создавать эффективные и надежные решения для вас.</p>
        </section>

        <section id="services" className="services">
          <IconTextGrid />
          <div className="service-list"></div>
        </section>
      </div>
      
      <footer>
        <p className='copyright'>©2024 zhako.kz <br /> Все права защищены</p>
      </footer>
    </div>
  );
}
